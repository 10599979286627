import React from "react";
import "./App.css";
import WeatherForm from "./components/WeatherForm.components";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h3>Wrather api is from Meteo.lt</h3>
        <p>
          Enter a city to get clothing recommendations for next 3days, depending
          on the weather.
        </p>
        <WeatherForm />
      </header>
    </div>
  );
}

export default App;
