import React from "react";
import ReactLoading from "react-loading";

export default function RecommendationTable(props) {
  return (
    <>
      {props.isLoading ? (
        <div className="load">
          <ReactLoading type={"spin"} color={"blue"} height={50} width={50} />
        </div>
      ) : (
        <></>
      )}
      <div className="scroll">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Forecast Time (UTC)</th>
              <th>Condition Code</th>
              <th>Recommendations</th>
            </tr>
          </thead>
          {props.weatherRecommendations.message ? (
            <tbody>
              <tr>
                <td colSpan="4" style={{ textAlign: "center" }}>
                  <p>{props.weatherRecommendations.message}</p>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {props.weatherRecommendations.map((recommendation) => (
                <tr key={recommendation.forecastTimeUtc}>
                  <td>{recommendation.name}</td>
                  <td>{recommendation.forecastTimeUtc}</td>
                  <td>{recommendation.conditionCode}</td>
                  <td>
                    <ul>
                      {recommendation.recommendations.map((item) => (
                        <li key={item.sku}>
                          {item.name} (SKU: {item.sku}) - ${item.price}
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
}
