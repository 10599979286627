import axios from "axios";

export const baseURL = process.env.REACT_APP_VITE_API_BASE_URL;

export const maxFileSize = 50000000;

//URL for api
const instance = axios.create({
  baseURL: baseURL + "/api",
});

export default instance;
