import React, { useState, useEffect } from "react";
import axios from "../axios";
import RecommendationTable from "./RecommendationTable.components";

export default function WeatherForm() {
  const [cities, setCities] = useState([]);
  const [weatherRecommendations, setWeatherRecommendations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios
      .get("/city-names")
      .then((res) => {
        setCities(res.data);
      })
      .catch((err) => {
        console.log("Something went wrong fetching city list");
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const city = event.target.value.toLowerCase();
    const formProduct = { city: city };
    axios
      .post("/clothes-recomendations", formProduct)
      .then((res) => {
        setWeatherRecommendations(res.data);
        setIsLoading(false);
      })
      .catch(() => {
        console.log("Something went wrong please try later");
        setIsLoading(false);
      });
  };

  const handleKeyDown = (event) => {
    const input = event.target.value.trim();
    if (event.key === "Enter" && input !== "") {
      handleSubmit(event);
      setIsLoading(true);
    }
  };

  return (
    <>
      <form>
        <input
          type="text"
          id="city"
          name="city"
          placeholder="City name"
          list="cities"
          onKeyDown={handleKeyDown}
          maxLength="25"
          required
        />
        <datalist id="cities">
          {cities.map((city) => (
            <option key={city} value={city} />
          ))}
        </datalist>
      </form>
      <RecommendationTable
        weatherRecommendations={weatherRecommendations}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </>
  );
}
